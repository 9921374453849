import styled from '@emotion/styled';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Tooltip } from '@mui/material';
import { language } from '../Language';

interface Props {
  className?: string;
  toggleSideMenu: () => void;
}

const strings = language().menu.top;

const TopMenu = (props: Props) => {
  const { className, toggleSideMenu } = props;

  const rightSide = (
    <Tooltip title={strings.gear}>
      <IconButton
        onClick={toggleSideMenu}
        sx={{
          borderRadius: '50%',
        }}
      >
        <SettingsIcon
          sx={{
            animation: 'spin 7s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
            color: '#666666',
          }}
        />
      </IconButton>
    </Tooltip>
  );

  return (
    <div className={className}>
      <div id='left'>
        <img src='/favicon.ico' alt='Favicon' />
      </div>
      <div id='right'>
        {rightSide}
      </div>
    </div>
  );
};

export default styled(TopMenu)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 20px 40px;
  margin-bottom: 10px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #left {
    img {
      height: 50px;
    }
  }

  #right {

  }
`;
