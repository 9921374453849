import styled from '@emotion/styled';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { PasswordIcon } from '../../../Icons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  strings: any;
}

interface UserInput {
  email: string;
}

const ForgotPasswordRightSide = (props: Props) => {
  const { className, strings } = props;

  const navigate = useNavigate();

  const [userInput, setUserInput] = useState<UserInput>({ email: '' });

  const handleSendRequest = () => {
    navigate('/update-password?email=' + encodeURIComponent(userInput.email));
  };

  return (
    <Grid className={className} item xs={12} md={8}>
      <div id='right-content'>
        <div id='icon'>
          <PasswordIcon />
        </div>
        <div id='title'>
          <Typography variant='h4'>{strings.title}</Typography>
        </div>
        <div id='subtitle'>
          <Typography variant='body2' color='#637381'>{strings.subtitle}</Typography>
        </div>

        <TextField
          label={strings.email}
          variant='outlined'
          fullWidth
          placeholder='example@gmail.com'
          value={userInput.email}
          onChange={(e) => setUserInput((_userInput) => ({ ..._userInput, email: e.target.value }))}
        />

        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSendRequest}
        >
          {strings.sendRequest}
        </Button>
        <div id='return-to-signin'>
          <ArrowBackIosIcon />
          <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
            {strings.returnToSignIn}
          </Link>
        </div>
      </div>
    </Grid >
  );
};

export default styled(ForgotPasswordRightSide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  
  #right-content {
    width: 420px;
    
    & > * {
      padding-bottom: 10px;
    }
  }

  #icon {
    display: flex;
    justify-content: center;
  }

  #title {
    display: flex;
    justify-content: center;
  }

  #subtitle {
    display: flex;
    flex-direction: row;
    text-align: center;

    & > * {
      padding-right: 5px;
    }
  }

  #return-to-signin {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  
`;
