import styled from '@emotion/styled';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  strings: any;
}

interface UserInput {
  email: string;
  password: string;
}

const SignInRightSide = (props: Props) => {
  const { className, strings } = props;

  const [userInput, setUserInput] = useState<UserInput>({ email: '', password: '' });

  const handleSignIn = () => {

  };

  return (
    <Grid className={className} item xs={12} md={8}>
      <div id='right-content'>
        <div>
          <Typography variant='h4'>{strings.title}</Typography>
        </div>
        <div id='subtitle'>
          <Typography variant='body2' color='#637381'>{strings.subtitle}</Typography>
          <Link to='/sign-up'>{strings.getStarted}</Link>
        </div>

        <TextField
          label={strings.email}
          variant='outlined'
          fullWidth
          value={userInput.email}
          onChange={(e) => setUserInput((_userInput) => ({ ..._userInput, email: e.target.value }))}
        />
        <div id='forgot-password'>
          <Link
            to='/forgot-password'
            style={{ textDecoration: 'none', color: 'black' }}
          >
            {strings.forgotPassword}
          </Link>
        </div>

        <TextField
          label={strings.password}
          variant='outlined'
          fullWidth
          value={userInput.password}
          onChange={(e) => setUserInput((_userInput) => ({ ..._userInput, password: e.target.value }))}
          type='password'
        />

        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSignIn}
        >
          {strings.signIn}
        </Button>
      </div>
    </Grid>
  );
};

export default styled(SignInRightSide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  
  #right-content {
    width: 420px;
    
    & > * {
      padding-bottom: 10px;
    }
  }

  #subtitle {
    display: flex;
    flex-direction: row;

    & > * {
      padding-right: 5px;
    }
  }
  
  #forgot-password {
    display: flex;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

`;
