import styled from '@emotion/styled';
import { Drawer, Grid } from '@mui/material';
import SideMenuButtons from './SideMenuButtons';
import { language } from '../../Language';

interface Props {
  className?: string;
  isOpen: boolean;
  toggleSideMenu: () => void;
}

const strings = language().menu;

const SideMenu = (props: Props) => {
  const { className, isOpen, toggleSideMenu } = props;

  return (
    <Drawer
      open={isOpen}
      anchor='right'
      onClose={toggleSideMenu}
    >
      <Grid container className={className}>
        <SideMenuButtons strings={strings.left} toggleSideMenu={toggleSideMenu} />
      </Grid>
    </Drawer>
  );
};

export default styled(SideMenu)`
  width: 360px;
  padding: 20px
`;
