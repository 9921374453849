import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';

export const ThemeContext = React.createContext(
  (themeName: string): void => { }
);

const DEFAULT_THEME_NAME = 'AspirEdTheme';

const ThemeProviderWrapper = ({ children }: any) => {
  const localThemeName = sessionStorage.getItem('appTheme') || DEFAULT_THEME_NAME;
  const [themeName, _setThemeName] = useState(localThemeName);

  const setThemeName = (themeName: string): void => {
    sessionStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <ThemeContext.Provider value={setThemeName} >
      <ThemeProvider theme={themeCreator(themeName)}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
