const en = {
  menu: {
    top: {
      gear: 'Settings',
    },
    left: {
      settings: 'Settings',
    }
  },
  signedIn: {
  },
  signedOut: {
    signIn: {
      left: {
        title: 'Hi, Welcome back',
        subTitle: 'More effectively with optimized workflows',
      },
      right: {
        title: 'Sign in to your account',
        subtitle: 'Don\'t have an account?',
        getStarted: 'Get started',
        email: 'Email address',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        signIn: 'Sign in',
      }
    },
    signUp: {
      title: 'Sign up',
      signInButton: 'Sign in',
      signUpButton: 'Sign up',
      emailInput: 'Email',
      passwordInput: 'Password',
      confirmPasswordInput: 'Confirm password',
    },
    forgotPassword: {
      left: {
        title: 'Hi, Welcome back',
        subTitle: 'More effectively with optimized workflows',
      },
      right: {
        title: 'Forgot your password?',
        subtitle: 'Please enter the email address associated with your account and we\'ll email you a link to reset your password.',
        email: 'Email address',
        sendRequest: 'Send Request',
        returnToSignIn: 'Return to sign in',
      },
    },
    updatePassword: {
      left: {
        title: 'Hi, Welcome back',
        subTitle: 'More effectively with optimized workflows',
      },
      right: {
        title: 'Request sent successfully!',
        subtitle: 'We\'ve sent a 6-digit confirmation email to your email. Please enter the code in below box to verify your email.',
        email: 'Email address',
        password: 'Password',
        passwordPlaceHolder: '6+ characters',
        confirmPassword: 'Confirm Password',
        updatePassword: 'Update Password',
        dontHaveCode: 'Don\'t have the code?',
        resendCode: 'Resend code',
        returnToSignIn: 'Return to sign in',
      }
    }
  }
};

export default en;
