import styled from '@emotion/styled';
import { language } from '../../Language';

interface Props {
    className?: string;
    isSmallScreen: boolean;
}

const strings = language().signedOut.signUp;

const SignUp = (props: Props) => {
    const { className } = props;

    return (
        <div className={className}>
            {strings.title}
        </div>
    );
};

export default styled(SignUp)`

`;
