import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { StoreContextProvider } from './store';
import ThemeProviderWrapper from './Theme';
import { CssBaseline } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProviderWrapper>
      <StoreContextProvider>
        <CssBaseline />
        <App />
      </StoreContextProvider>
    </ThemeProviderWrapper>
  </React.StrictMode>
);

