import styled from '@emotion/styled';
import { Grid, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  className?: string;
  toggleSideMenu: () => void;
  strings: any;
}

const SideMenuButtons = (props: Props) => {
  const { className, strings, toggleSideMenu } = props;

  return (
    <Grid item className={className}>
      <div id='left'>
        <Typography variant='h4'>{strings.settings}</Typography>
      </div>
      <div id='right'>
        <IconButton onClick={toggleSideMenu} style={{ borderRadius: '50%' }}>
          <ClearIcon />
        </IconButton>
      </div>
    </Grid>
  );
};

export default styled(SideMenuButtons)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
