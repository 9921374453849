import styled from '@emotion/styled';
import { language } from '../../../Language';
import { Grid } from '@mui/material';
import ForgotPasswordRightSide from './RightSide';
import ForgotPasswordLeftSide from './LeftSide';

interface Props {
  className?: string;
  isSmallScreen: boolean;
}

const strings = language().signedOut.forgotPassword;

const ForgotPassword = (props: Props) => {
  const { className, isSmallScreen } = props;

  return (
    <Grid className={className} container>
      {!isSmallScreen && <ForgotPasswordLeftSide strings={strings.left} />}
      <ForgotPasswordRightSide strings={strings.right} />
    </Grid>
  );
};

export default styled(ForgotPassword)`

`;
