import styled from '@emotion/styled';
import { language } from '../../../Language';
import { Grid } from '@mui/material';
import UpdatePasswordRightSide from './RightSide';
import UpdatePasswordLeftSide from './../ForgotPassword/LeftSide';
import { useLocation } from 'react-router-dom';

interface Props {
  className?: string;
  isSmallScreen: boolean;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const strings = language().signedOut.updatePassword;

const UpdatePassword = (props: Props) => {
  const { className, isSmallScreen } = props;

  // Get email from query
  const query = useQuery();
  const email = query.get('email') || '';

  return (
    <Grid className={className} container>
      {!isSmallScreen && <UpdatePasswordLeftSide strings={strings.left} />}
      <UpdatePasswordRightSide email={email} strings={strings.right} />
    </Grid>
  );
};

export default styled(UpdatePassword)`

`;
