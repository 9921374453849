import { useState, createContext, SetStateAction, Dispatch } from 'react';

export type StoreContext = {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  sideMenu: boolean;
  toggleSideMenu: () => void;
};

export const storeContext = createContext(
  {} as StoreContext
);

export const StoreContextProvider = ({ children }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [sideMenu, setSideMenu] = useState<boolean>(false);

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    sideMenu,
    toggleSideMenu: () => setSideMenu((_sideMenu) => !_sideMenu),
  };


  return (
    <storeContext.Provider value={value}>
      {children}
    </storeContext.Provider>
  );
};
