
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './Pages/Entry/SignIn';
import SignUp from './Pages/Entry/SignUp';
import { useContext } from 'react';
import { storeContext } from './store';
import TopMenu from './Menus/TopMenu';
import SideMenu from './Menus/SideMenu';
import ForgotPassword from './Pages/Entry/ForgotPassword';
import { useMediaQuery, useTheme } from '@mui/material';
import UpdatePassword from './Pages/Entry/UpdatePassword';

const STORE_KEY = 'aspired';

const App = () => {
  const { isLoggedIn, setIsLoggedIn, sideMenu, toggleSideMenu } = useContext(storeContext);
  if (localStorage.getItem(STORE_KEY)) {
    setIsLoggedIn(true);
  }

  const isSmallScreen = useMediaQuery(`(max-width:${useTheme().breakpoints.values.md}px)`);

  return (
    <div className='App'>
      <TopMenu toggleSideMenu={toggleSideMenu} />
      <SideMenu isOpen={sideMenu} toggleSideMenu={toggleSideMenu} />
      <Router>
        <Routes>
          {
            !isLoggedIn ? (
              <>
                <Route path='/' element={<SignIn isSmallScreen={isSmallScreen} />} />
                <Route path='/sign-up' element={<SignUp isSmallScreen={isSmallScreen} />} />
                <Route path='/forgot-password' element={<ForgotPassword isSmallScreen={isSmallScreen} />} />
                <Route path='/update-password' element={<UpdatePassword isSmallScreen={isSmallScreen} />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
            ) : (
              <>
                <Route path='/' element={<div>Login</div>} />
                <Route path='*' element={<div>ERROR!</div>} />
              </>
            )
          }
        </Routes>
      </Router>
    </div>
  );
}

export default App;
