import styled from '@emotion/styled';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { SentIcon } from '../../../Icons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import OtpInput from './OtpInput';

interface Props {
  className?: string;
  strings: any;
  email?: string;
}

interface UserInput {
  email: string;
  password: string;
  confirmPassword: string;
}

const UpdatePasswordRightSide = (props: Props) => {
  const { className, strings, email } = props;

  const [userInput, setUserInput] = useState<UserInput>({ email: email || '', password: '', confirmPassword: '' });

  const handleUpdatePassword = () => {
    console.log('Update password');
  }

  return (
    <Grid className={className} item xs={12} md={8}>
      <div id='right-content'>
        <div id='icon'>
          <SentIcon />
        </div>
        <div id='title'>
          <Typography variant='h4'>{strings.title}</Typography>
        </div>
        <div id='subtitle'>
          <Typography variant='body2' color='#637381'>{strings.subtitle}</Typography>
        </div>

        <TextField
          label={strings.email}
          variant='outlined'
          fullWidth
          placeholder='example@gmail.com'
          value={userInput.email}
          disabled
        />
        <OtpInput
          length={6}
          onChange={(otp) => console.log(otp)}
        />

        <TextField
          label={strings.password}
          placeholder={strings.passwordPlaceHolder}
          variant='outlined'
          fullWidth
          value={userInput.password}
          onChange={(e) => setUserInput((_userInput) => ({ ..._userInput, password: e.target.value }))}
          type='password'
        />
        <TextField
          label={strings.confirmPassword}
          variant='outlined'
          fullWidth
          value={userInput.confirmPassword}
          onChange={(e) => setUserInput((_userInput) => ({ ..._userInput, confirmPassword: e.target.value }))}
          type='password'
        />

        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleUpdatePassword}
        >
          {strings.updatePassword}
        </Button>

        <div id='no-code'>
          <Typography variant='body2' color='#637381'>{strings.dontHaveCode}</Typography>
          <Typography
            id='resend-code'
            variant='body2'
          >
            {strings.resendCode}
          </Typography>
        </div>

        <div id='return-to-signin'>
          <ArrowBackIosIcon />
          <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
            {strings.returnToSignIn}
          </Link>
        </div>
      </div>
    </Grid >
  );
};

export default styled(UpdatePasswordRightSide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  
  #right-content {
    width: 420px;
    
    & > * {
      padding-bottom: 10px;
    }
  }

  #icon {
    display: flex;
    justify-content: center;
  }

  #title {
    display: flex;
    justify-content: center;
  }

  #subtitle {
    display: flex;
    flex-direction: row;
    text-align: center;

    & > * {
      padding-right: 5px;
    }
  }

  #no-code {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    & > * {
      padding-right: 5px;
    }

    #resend-code {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  #return-to-signin {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  
`;
